import React from "react"
import { useDispatch } from "react-redux"

import Button from "@ng-mw/reol/components/Button"
import SafeHtml from "@ng-mw/reol/components/SafeHtml"

import "./style/top-banner-popup.scss"
import { hideTopBannerPopup } from "@app/store/app/appActions"
import { BannerInfo } from "@app/store/settings/types"
import { PopupButtons, PopupContent, PopupText, PopupTitle } from "@modules/popup/Popup"

const TopBannerPopupContent = (bannerInfo: BannerInfo) => {
    const dispatch = useDispatch<any>()

    const handleClose = () => {
        dispatch(hideTopBannerPopup())
    }

    return (
        <>
            {bannerInfo?.appPopUpImage ? (
                <img
                    className="top-banner-popup__image"
                    src={bannerInfo.appPopUpImage}
                    aria-hidden="true"
                    alt=""
                />
            ) : null}
            {bannerInfo?.appPopUpTitle ? (
                <PopupTitle>
                    {bannerInfo.appPopUpTitle}
                </PopupTitle>
            ) : null}
            <PopupContent className="top-banner-popup__content">
                {bannerInfo?.appPopUpText ? (
                    <PopupText>
                        <SafeHtml html={bannerInfo.appPopUpText} />
                    </PopupText>
                ) : null}
            </PopupContent>
            <PopupButtons isSimple>
                <Button
                    onClick={handleClose}
                    isSecondary
                >
                    Fortsett å handle
                </Button>
            </PopupButtons>
        </>
    )
}

export default TopBannerPopupContent
