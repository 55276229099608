import PathNames from "@app/const/PathNames"
import { RootState } from "@app/types"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { mapPreparationTime } from "@modules/recipes/helpers"
import { getWeekMenu } from "@ng-mw/shared-meny-components/src/modules/WeekMenu/store/actions/weekmenuActions"
import getDay from "date-fns/getDay"

import CupcakeIcon from "@ng-mw/shared-meny-components/src/resources/cupcake"

import Container, { Content } from "@modules/shared/Container"
import Spinner from "@ng-mw/reol/components/Spinner"
import Tabs, { Tab } from "@ng-mw/reol/components/Tabs"
import { RecipeRating, RecipeTime } from "@ng-mw/shared-meny-components/recipes"
import SwiperCore, { A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import RecipeLink from "./RecipeLink"

import "./style/ultra-compact-weekmenu.scss"
// Import Swiper styles
require("swiper/swiper.scss")
require("swiper/components/pagination/pagination.scss")

SwiperCore.use([A11y])

const UltraCompactWeekMenu = () => {

    const dispatch = useDispatch<any>()
    const weekMenuData = useSelector((state: RootState) => state.weekmenu)

    useEffect(() => {
        dispatch(getWeekMenu())
    }, [dispatch])

    const [swiper, setSwiper] = useState<SwiperCore|undefined>(undefined)
    const today = new Date()

    // getDay return sunday as 0 but we want monday as 0 and sunday as 1
    /*
    (0 + 6) % 7 = 6
    (1 + 6) % 7 = 0
    (2 + 6) % 7 = 1
    (3 + 6) % 7 = 2
    (4 + 6) % 7 = 3
    (5 + 6) % 7 = 4
    (6 + 6) % 7 = 5
    */
    const initial = (6 + getDay(today)) % 7
    // Ignore checking year and weeknumber since this week menu always shows the current week
    const [activeDay, setActiveDay] = React.useState(initial)

    useEffect(() => {
        if (weekMenuData.data.length > 0 && swiper && !swiper.destroyed) {
            swiper.slideTo(activeDay)
        }
    }, [swiper, activeDay, weekMenuData])

    if (weekMenuData.loading || weekMenuData.data.length < 1) {
        return (
            <div className="week-menu-wrapper week-menu-wrapper--loading">
                <Spinner />
            </div>
        )
    }

    const recipeList = weekMenuData.data[0]?.RecipeList
    const maxNum = recipeList.length

    const activeIndexWrapped = ((activeDay % maxNum) + maxNum) % maxNum

    return (
        <Container
            title="Ukemeny"
            linkText="Se flere oppskrifter"
            to={PathNames.RecipesHome}
            content={Content.FullWidth}
        >
            <div
                className={classNames(
                    "ultra-compact-weekmenu",
                    {
                        // "ultra-compact-weekmenu--narrow": narrow,
                    })
                }
            >
                <div className="ultra-compact-weekmenu__tabwrapper">
                    <Tabs
                        className="ultra-compact-weekmenu__tabs"
                        onChange={(_e: any, value: number) => setActiveDay(value)}
                        value={activeIndexWrapped}
                    >

                        {recipeList.map(recipe => (
                            <Tab
                                key={`tab_${recipe.WeekDayShort}`}
                                label={recipe.WeekDayShort}
                                title={recipe.WeekDay}
                                aria-label={recipe.WeekDay}
                            />
                        ))}
                    </Tabs>
                    <Tabs
                        className="ultra-compact-weekmenu__tiny-tabs"
                        onChange={(_e: any, value: number) => setActiveDay(value)}
                        value={activeIndexWrapped}
                    >
                        {recipeList.map(recipe => (
                            <Tab
                                key={`tab_${recipe.WeekDayShort}`}
                                label={recipe.WeekDayShort.slice(0, 1)}
                                icon={recipe.WeekDayShort === "Bakst" ? CupcakeIcon : null}
                                title={recipe.WeekDay}
                                aria-label={recipe.WeekDay}
                            />
                        ))}
                    </Tabs>
                </div>
                <div className="ultra-compact-weekmenu__recipeimages">
                    <Swiper
                        spaceBetween={16}
                        slidesOffsetAfter={16}
                        slidesOffsetBefore={16}
                        slidesPerView={1.2}
                        onSlideChange={swiper => setActiveDay(swiper.activeIndex)}
                        onSwiper={swiper => setSwiper(swiper)}
                        onBeforeDestroy={() => setSwiper(undefined)}
                        initialSlide={activeDay}
                    >
                        {recipeList.map(recipe => (
                            <SwiperSlide
                                key={`img_${recipe.WeekDay}`}
                                className="ultra-compact-weekmenu__card"
                                aria-labelledby={`thelink_${recipe.WeekDay}`}
                            >
                                <RecipeLink
                                    recipeId={recipe.RecipeId}
                                    aria-hidden="true"
                                >
                                    <img
                                        src={recipe.Image}
                                        alt=""
                                        width="112"
                                        height="114"
                                    />
                                </RecipeLink>
                                <div className="ultra-compact-weekmenu__texts">

                                    <RecipeRating
                                        recipeId={recipe.RecipeId}
                                        isReadOnly={true}
                                        className="ultra-compact-weekmenu__rating"
                                    />
                                    <RecipeLink
                                        recipeId={recipe.RecipeId}
                                        id={`thelink_${recipe.WeekDay}`}
                                    >
                                        <h3 className="c-h5">{recipe.Title}</h3>
                                    </RecipeLink>
                                    <RecipeTime time={mapPreparationTime(recipe.TimeEstimate)} />
                                </div>

                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>
        </Container>
    )
}

export default UltraCompactWeekMenu
