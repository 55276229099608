import "./style/tips.scss"

import React, { ReactNode } from "react"
import classNames from "classnames"

// Components
import NgrIcon from "@ng-mw/reol/components/Icon"
import { CloseButton } from "@ng-mw/reol/components/Modal"
import { Spacing, SpacingEnum } from "@app/types"

export const TipsSize = {
    None: "none",
    Small: "small",
    Large: "large",
} as const

export type TipsSizeEnum = typeof TipsSize[keyof typeof TipsSize];

export const TitleFont = {
    FuturaPassata: "futura",
    Mulish: "mulish",
} as const

export type TitleFontEnum = typeof TitleFont[keyof typeof TitleFont];

interface Size {
    height: string
    width: string
}

export const Background = {
    None: "none",
    White: "white",
    Gray: "gray",
    LightRed: "light-red",
    LightGreen: "light-green",
} as const

export type BackgroundEnum = typeof Background[keyof typeof Background];
interface TipsProps {
    className?: string
    size?: TipsSizeEnum | Size
    title?: string
    text?: string
    children?: ReactNode
    illustration?: any
    illustrationLeftOffset?: string
    illustrationLTopOffset?: string
    background?: BackgroundEnum
    onClose?: any
    isVisible?: boolean
    titleFont?: TitleFontEnum
    padding?: SpacingEnum
}

const Tips = ({
    className,
    size = TipsSize.Small,
    title,
    text,
    children,
    illustration,
    illustrationLeftOffset = "-60px",
    illustrationLTopOffset = "0",
    background = Background.Gray,
    padding = Spacing.Medium,
    onClose = null,
    isVisible = true,
    titleFont = TitleFont.FuturaPassata,
}: TipsProps) => {
    const handleCloseClick = () => {
        onClose()
    }

    const getSize = () => {
        switch (size) {
            case TipsSize.None:
                return ({ height: "24px", width: "24px" })
            case TipsSize.Small:
                return ({ width: "118px", height: "118px" })
            case TipsSize.Large:
                return ({ width: "136px", height: "41px" })
            default:
                return (size)
        }
    }

    const appTipsClassNames = classNames(
        "app-tips",
        className,
        `app-tips--background-${background}`,
        `app-tips--padding-${padding}`,
        {
            "app-tips--large": size === TipsSize.Large,
            "app-tips--small": size === TipsSize.Small,
        },
    )

    if (!isVisible) return null

    return (
        <div className={appTipsClassNames}>
            {onClose ? (
                <div
                    className="app-tips__close-button"
                    onClick={handleCloseClick}
                >
                    <CloseButton />
                </div>
            ) : null}
            {illustration ? (
                <div
                    className="app-tips__illustration"
                    style={{ transform: size !== TipsSize.Large ? `translateX(${illustrationLeftOffset}) translateY(${illustrationLTopOffset})` : "none" }}
                >
                    <NgrIcon
                        icon={illustration}
                        size={getSize()}
                    />
                </div>
            ) : null}
            <div
                className="app-tips__container"
                style={{ marginLeft: size !== TipsSize.Large ? `${illustrationLeftOffset}` : "0px" }}
            >
                {title ? (
                    <h3 className={classNames("app-tips__title", `app-tips__title--${titleFont}`)}>
                        {title}
                    </h3>
                ) : null}
                {text ? (
                    <p className="app-tips__text">
                        {text}
                    </p>
                ) : null}
                {children}
            </div>
        </div>
    )
}

export default Tips
