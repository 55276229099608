import React from "react"
import { LifecycleEmitter, LifecycleEvent } from "@ng-mw/shared-react-components"

import "./style/recipe-link.scss"

interface RecipeLinkProps {
    recipeId: string
    children?: React.ReactNode[] | React.ReactNode
    id?: string
}

const RecipeLink = ({
    recipeId,
    children,
    id,
    ...rest
}: RecipeLinkProps) => {

    const nativeRecipeClicked = () => {
        LifecycleEmitter.broadcast(LifecycleEvent.GoToRecipe, {recipeId})
    }

    return (
        <button
            className="recipe-link"
            onClick={nativeRecipeClicked}
            id={id}
            {...rest}
        >
            {children}
        </button>
    )
}

export default RecipeLink
