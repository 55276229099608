import PopupNames from "@app/const/PopupNames"
import * as tracking from "@app/tracking"
import { App as AppState } from "@app/types/root-state/app"
import { GenericAction } from "@app/types"
import { AppAction } from "./appCreator"

const init: AppState = {
    scrollPosition: {
        top: true,
        bottom: false,
        goingDown: false,
    },
    popupScrollPosition: {
        top: true,
        goingDown: false,
    },
    appUpgradePopup: {
        visible: false,
    },
    osUpgradePopup: {
        visible: false,
    },
    forcedUpgradePopup: {
        visible: false,
    },
    categoryFilterPopup: {
        visible: false,
    },
    couponPopup: {
        visible: false,
    },
    productPopup: {
        visible: false,
        scanMode: false,
    },
    barcodePopup: {
        visible: false,
        productEan: "",
    },
    recipeRatingPopup: {
        visible: false,
        recipeItem: undefined,
    },
    recipePopup: {
        visible: false,
        recipeId: "",
    },
    trumfIdModal: {
        visible: false,
    },
    appOnboardingPoster: {
        visible: false,
    },
    loginPopup: {
        visible: false,
        title: "",
        text: "",
    },
    logOutPopup: {
        visible: false,
        title: "Den er grei!",
        text: "For å få tilgang til alle funksjonene i appen må du være logget inn som MENY MER-kunde. Hvis du ombestemmer deg senere kan du når som helst logge inn og registrere deg i kundeklubben.",
    },
    registrationPopup: {
        visible: false,
        data: undefined,
    },
    handoverPickerPopup: {
        visible: false,
    },
    checkoutPaymentPopup: {
        visible: false,
        paymentUrl: "",
    },
    insightAboutPopup: {
        visible: false,
    },
    feedbackPopup: {
        visible: false,
        data: undefined,
    },
    insightKeyFiguresPopup: {
        visible: false,
        data: null,
    },
    climateNumbersPopup: {
        visible: false,
        data: null,
    },
    climateComparisonPopup: {
        visible: false,
        data: null,
    },
    climateInformationPopup: {
        visible: false,
    },
    climateProductGroupsInfoPopup: {
        visible: false,
    },
    dietEnergyPopup: {
        visible: false,
    },
    dietGroupPopup: {
        visible: false,
        tabIndex: 0,
    },
    dietPropertyPopup: {
        visible: false,
    },
    topBannerPopup: {
        visible: false,
    },
    // Transactions
    purchaseDetailsPopup: {
        visible: false,
    },
    purchaseComplaintPopup: {
        visible: false,
    },
    pushConsentsPopup: {
        visible: false,
    },
    qrPopup: {
        visible: false,
    },
    agreementDeactivationPopup: {
        visible: false,
    },
    removeShoppingListPopup: {
        visible: false,
        shoppingList: undefined,
    },
    changeNameShoppingListPopup: {
        visible: false,
    },
    createShoppingListPopup: {
        visible: false,
    },
    onboardingPoster: {
        visible: false,
    },
    newsPopup: {
        visible: false,
        data: {
            text: "",
            title: "",
            localStorageKey: "",
            cloudinaryId: "",
        },
    },
    activateLoyaltyClubPopup: {
        visible: false,
    },
    welcomePopup: {
        visible: false,
        data: null,
    },
    tmpPopup: {
        visible: false,
    },
    loyaltyClubInfoPopup: {
        visible: false,
    },
    personalCampaignsPopup: {
        visible: false,
    },
    gamePopup: {
        visible: false,
        data: {
            isJulelotteri: false,
            isKronelotteri: false,
        },
    },
    isFullscreenPopup: false,
    activePopups: [],
    lastBuild: process.env.LAST_BUILD,
    version: process.env.APP_VERSION,
}

const showPopup = (state: AppState, name: string, moreState = {}, isFullscreenPopup = false) => {
    tracking.modalOpened(name, moreState)
    return {
        ...state,
        activePopups: [...state.activePopups, name],
        isFullscreenPopup: isFullscreenPopup,
        [name]: {
            visible: true,
            ...moreState,
        },
    }
}
const hidePopup = (state: AppState, name: string, moreState = {}) => {
    tracking.modalClosed(name, moreState)
    return {
        ...state,
        activePopups: state.activePopups.filter(i => i !== name),
        isFullscreenPopup: false,
        [name]: {
            ...state[name],
            visible: false,
            ...moreState,
        },
    }
}

export default (state = init, action: GenericAction) => {
    switch (action.type) {
        case AppAction.ScrollPositionChange: {
            const postion = action.payload

            return {
                ...state,
                scrollPosition: {
                    top: postion.top,
                    bottom: postion.bottom,
                    goingDown: postion.goingDown,
                },
            }
        }
        case AppAction.PopupScrollPositionChange: {
            const postion = action.payload

            return {
                ...state,
                popupScrollPosition: {
                    top: postion.top,
                    goingDown: postion.goingDown,
                },
            }
        }
        case AppAction.ShowCategoryFilterPopup: return showPopup(state, PopupNames.categoryFilterPopup, { productViewId: action.payload }, true)
        case AppAction.HideCategoryFilterPopup: return hidePopup(state, PopupNames.categoryFilterPopup)
        case AppAction.ShowProductPopup: return showPopup(state, PopupNames.productPopup, {}, true)
        case AppAction.HideProductPopup: return hidePopup(state, PopupNames.productPopup)
        case AppAction.ShowBarcodePopup: return showPopup(state, PopupNames.barcodePopup, {}, true)
        case AppAction.HideBarcodePopup: return hidePopup(state, PopupNames.barcodePopup, { productEan: {} })
        case AppAction.BarcodeFound: {
            const productEan = action.payload
            return showPopup(state, PopupNames.barcodePopup, { productEan })
        }

        case AppAction.ShowRecipePopup: {
            const recipeId = action.payload
            return showPopup(state, PopupNames.recipePopup, { recipeId }, true)
        }
        case AppAction.HideRecipePopup: return hidePopup(state, PopupNames.recipePopup)

        case AppAction.ShowRecipeRatingPopup: {
            const recipeItem = action.payload
            return showPopup(state, PopupNames.recipeRatingPopup, { recipeItem })
        }
        case AppAction.HideRecipeRatingPopup: return hidePopup(state, PopupNames.recipeRatingPopup)
        case AppAction.ShowTrumfIdModal: return showPopup(state, PopupNames.trumfIdModal)
        case AppAction.HideTrumfIdModal: return hidePopup(state, PopupNames.trumfIdModal)
        case AppAction.ShowAppOnboardingPoster: return showPopup(state, PopupNames.appOnboardingPoster, {}, true)
        case AppAction.HideAppOnboardingPoster: return hidePopup(state, PopupNames.appOnboardingPoster)
        case AppAction.ShowRegistrationPopup: return showPopup(state, PopupNames.registrationPopup, { data: action.payload }, true)
        case AppAction.HideRegistrationPopup: return hidePopup(state, PopupNames.registrationPopup)
        case AppAction.ShowCouponInfoPopup: return showPopup(state, PopupNames.couponPopup)
        case AppAction.HideCouponInfoPopup: return hidePopup(state, PopupNames.couponPopup)
        case AppAction.ShowHandoverPickerPopup: return showPopup(state, PopupNames.handoverPickerPopup, {}, true)
        case AppAction.HideHandoverPickerPopup: return hidePopup(state, PopupNames.handoverPickerPopup)
        case AppAction.ShowCheckoutPaymentPopup: return showPopup(state, PopupNames.checkoutPaymentPopup, { paymentUrl: action.payload }, true)
        case AppAction.HideCheckoutPaymentPopup: return hidePopup(state, PopupNames.checkoutPaymentPopup, { paymentUrl: {} })
        case AppAction.ShowInsightAboutPopup: return showPopup(state, PopupNames.insightAboutPopup, {}, true)
        case AppAction.HideInsightAboutPopup: return hidePopup(state, PopupNames.insightAboutPopup)
        case AppAction.ShowFeedbackPopup: return showPopup(state, PopupNames.feedbackPopup, { data: action.payload }, true)
        case AppAction.HideFeedbackPopup: return hidePopup(state, PopupNames.feedbackPopup, { data: {} })
        case AppAction.ShowInsightKeyFiguresPopup: return showPopup(state, PopupNames.insightKeyFiguresPopup, { data: action.payload }, true)
        case AppAction.HideInsightKeyFiguresPopup: return hidePopup(state, PopupNames.insightKeyFiguresPopup)
        case AppAction.ShowClimateNumbersPopup: return showPopup(state, PopupNames.climateNumbersPopup, {}, true)
        case AppAction.HideClimateNumbersPopup: return hidePopup(state, PopupNames.climateNumbersPopup, { data: {} })
        case AppAction.ShowClimateComparisonPopup: return showPopup(state, PopupNames.climateComparisonPopup, { data: action.payload }, true)
        case AppAction.HideClimateComparisonPopup: return hidePopup(state, PopupNames.climateComparisonPopup, { data: {} })
        case AppAction.ShowClimateInformationPopup: return showPopup(state, PopupNames.climateInformationPopup, {}, true)
        case AppAction.HideClimateInformationPopup: return hidePopup(state, PopupNames.climateInformationPopup)
        case AppAction.ShowClimateProductGroupsInfoPopup: return showPopup(state, PopupNames.climateProductGroupsInfoPopup, {}, true)
        case AppAction.HideClimateProductGroupsInfoPopup: return hidePopup(state, PopupNames.climateProductGroupsInfoPopup)
        case AppAction.ShowDietEnergyPopup: return showPopup(state, PopupNames.dietEnergyPopup, {}, true)
        case AppAction.HideDietEnergyPopup: return hidePopup(state, PopupNames.dietEnergyPopup)
        case AppAction.ShowDietGroupPopup: return showPopup(state, PopupNames.dietGroupPopup, {}, true)
        case AppAction.SetDietGroupPopupTabIndex: return showPopup(state, PopupNames.dietGroupPopup, { tabIndex: action.payload }, true)
        case AppAction.HideDietGroupPopup: return hidePopup(state, PopupNames.dietGroupPopup)
        case AppAction.ShowDietPropertyPopup: return showPopup(state, PopupNames.dietPropertyPopup, {}, true)
        case AppAction.HideDietPropertyPopup: return hidePopup(state, PopupNames.dietPropertyPopup)
        case AppAction.ShowInsightOnboardingPopup: return showPopup(state, PopupNames.insightOnboardingPopup, {}, true)
        case AppAction.HideInsightOnboardingPopup: return hidePopup(state, PopupNames.insightOnboardingPopup)
        case AppAction.ShowLoyaltyClubInfoPopup: return showPopup(state, PopupNames.loyaltyClubInfoPopup, {}, true)
        case AppAction.HideLoyaltyClubInfoPopup: return hidePopup(state, PopupNames.loyaltyClubInfoPopup)
        case AppAction.ShowTopBannerPopup: return showPopup(state, PopupNames.topBannerPopup)
        case AppAction.HideTopBannerPopup: return hidePopup(state, PopupNames.topBannerPopup)

        // Transactions
        case AppAction.ShowPurchaseDetailsPopup: return showPopup(state, PopupNames.purchaseDetailsPopup, {}, true)
        case AppAction.HidePurchaseDetailsPopup: return hidePopup(state, PopupNames.purchaseDetailsPopup)
        case AppAction.ShowPurchaseComplaintPopup: return showPopup(state, PopupNames.purchaseComplaintPopup)
        case AppAction.HidePurchaseComplaintPopup: return hidePopup(state, PopupNames.purchaseComplaintPopup)

        case AppAction.ShowQrPopup: return showPopup(state, PopupNames.qrPopup, {}, true)
        case AppAction.HideQrPopup: return hidePopup(state, PopupNames.qrPopup)

        case AppAction.ShowAppUpgradePopup: return showPopup(state, PopupNames.appUpgradePopup)
        case AppAction.HideAppUpgradePopup: return hidePopup(state, PopupNames.appUpgradePopup)
        case AppAction.ShowOsUpgradePopup: return showPopup(state, PopupNames.osUpgradePopup, {}, true)
        case AppAction.HideOsUpgradePopup: return hidePopup(state, PopupNames.osUpgradePopup)
        case AppAction.ShowForcedUpgradePopup: return showPopup(state, PopupNames.forcedUpgradePopup, {}, true)
        case AppAction.HideForcedUpgradePopup: return hidePopup(state, PopupNames.forcedUpgradePopup)
        case AppAction.ShowNewsPopup: {
            const data = action.payload
            return showPopup(state, PopupNames.newsPopup, { data })
        }
        case AppAction.HideNewsPopup: return hidePopup(state, PopupNames.newsPopup)
        case AppAction.ShowActivateLoyaltyClubPopup: return showPopup(state, PopupNames.activateLoyaltyClubPopup, {}, true)
        case AppAction.HideActivateLoyaltyClubPopup: return hidePopup(state, PopupNames.activateLoyaltyClubPopup)
        case AppAction.ShowShoppingListRemovePopup: return showPopup(state, PopupNames.removeShoppingListPopup, { shoppingList: action.payload })
        case AppAction.HideShoppingListRemovePopup: return hidePopup(state, PopupNames.removeShoppingListPopup)
        case AppAction.ShowShoppingListChangeNamePopup: return showPopup(state, PopupNames.changeNameShoppingListPopup, { data: action.payload })
        case AppAction.HideShoppingListChangeNamePopup: return hidePopup(state, PopupNames.changeNameShoppingListPopup)
        case AppAction.ShowShoppingListCreatePopup: return showPopup(state, PopupNames.createShoppingListPopup)
        case AppAction.HideShoppingListCreatePopup: return hidePopup(state, PopupNames.createShoppingListPopup)
        case AppAction.ShowPushConsentsPopup: return showPopup(state, PopupNames.pushConsentsPopup)
        case AppAction.HidePushConsentsPopup: return hidePopup(state, PopupNames.pushConsentsPopup)
        case AppAction.ShowAgreementDeactivationPopup: return showPopup(state, PopupNames.agreementDeactivationPopup)
        case AppAction.HideAgreementDeactivationPopup: return hidePopup(state, PopupNames.agreementDeactivationPopup)
        case AppAction.ShowPersonalCampaignsPopup: return showPopup(state, PopupNames.personalCampaignsPopup, {}, true)
        case AppAction.HidePersonalCampaignsPopup: return hidePopup(state, PopupNames.personalCampaignsPopup)
        case AppAction.ShowLoginPopup: {
            const { title, text, externalLink, externalLinkText, imageUrl } = action.payload || state.loginPopup
            return showPopup(state, PopupNames.loginPopup, { title, text, externalLink, externalLinkText, imageUrl })
        }
        case AppAction.HideLoginPopup: return hidePopup(state, PopupNames.loginPopup)
        case AppAction.ShowLogOutPopup: {
            const { title, text } = action.payload || state.logOutPopup
            return showPopup(state, PopupNames.logOutPopup, { title, text })
        }
        case AppAction.HideLogOutPopup: return hidePopup(state, PopupNames.logOutPopup)
        case AppAction.ShowWelcomePopup: {
            return showPopup(state, PopupNames.welcomePopup, { data: action.payload })
        }
        case AppAction.HideWelcomePopup: return hidePopup(state, PopupNames.welcomePopup)

        case AppAction.ShowTmpPopup: return showPopup(state, PopupNames.tmpPopup)
        case AppAction.HideTmpPopup: return hidePopup(state, PopupNames.tmpPopup)

        case AppAction.ShowGamePopup: {
            return showPopup(state, PopupNames.gamePopup, { data: action.payload })
        }
        case AppAction.HideGamePopup: return hidePopup(state, PopupNames.gamePopup)

        default:
            return state
    }
}
