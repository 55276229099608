import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideTopBannerPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"
import TopBannerPopupContent from "./TopBannerPopupContent"

// Components
import Popup from "@modules/popup/Popup"

const TopBannerPopup = () => {
    const { bannerInfo } = useSelector((state: RootState) => state.settings)
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.topBannerPopup.visible)

    return (
        <Popup
            onClose={() => dispatch(hideTopBannerPopup())}
            isOpen={isOpen}
            hideCloseButton={true}
            isHeaderActionsOverflow={bannerInfo?.appPopUpImage ? true : false}
            className="top-banner-popup"
        >
            <TopBannerPopupContent {...bannerInfo} />
        </Popup>
    )
}

export default TopBannerPopup
