import type { Action } from "redux"

// =============================================================================
// Actions
// -----------------------------------------------------------------------------

export enum SettingsActionTypes {
    Loading = "app-settings:loading",
    Loaded = "app-settings:loaded",
    Success = "app-settings:success",
    Error = "app-settings:error",
    HideMaintenanceMessage = "app-settings:hide-maintenance-message",
    HideCustomerServiceMessage = "app-settings:hide-customer-service-message"
}

export type SettingsLoadingAction = Action<SettingsActionTypes.Loading>
export type SettingsLoadedAction = Action<SettingsActionTypes.Loaded>

export interface SettingsSuccessAction extends Action<SettingsActionTypes.Success> {
    payload: SettingsApiResponse
}

export interface SettingsErrorAction extends Action<SettingsActionTypes.Error> {
    error: string | Error
}

export type SettingsHideMaintenanceMessageAction = Action<SettingsActionTypes.HideMaintenanceMessage>

export type SettingsHideCustomerServiceMessageAction = Action<SettingsActionTypes.HideCustomerServiceMessage>

export type SettingsAction =
    SettingsLoadingAction |
    SettingsLoadedAction |
    SettingsSuccessAction |
    SettingsErrorAction |
    SettingsHideMaintenanceMessageAction |
    SettingsHideCustomerServiceMessageAction

// =============================================================================
// State
// -----------------------------------------------------------------------------

export interface MaintenanceMessageState extends MaintenanceMessage {
    /**
     * Hides the message until next app load when closed by the user.
     */
    userHidden: boolean
}

export interface CustomerServiceMessageState extends CustomerServiceMessage {
    /**
     * Hides the message until next app load when closed by the user.
     */
    userHidden: boolean
}

export interface SettingsState {
    loading: boolean

    /**
     * Recipe IDs of the currently active dinners of the week.
     */
    //dinnersOfTheWeek: string[];

    /**
     * A message displayed to the user during maintenance.
     */
    maintenanceMessage: MaintenanceMessageState

    customerServiceMessage: CustomerServiceMessageState
    bannerInfo: BannerInfo
}

// =============================================================================
// API types
// -----------------------------------------------------------------------------

export interface SettingsApiConfig {
    url: string
    endpoint: string
    token: string
}

interface WeeklyDinnerPair {
    publishDate: string
    recipe1Name: string
    recipe1Id: string
    recipe2Name: string
    recipe2Id: string
}

export interface BannerInfo {
    enableTopBanner: boolean
    disableTopBannerForAuthenticatedUsers: boolean
    topBannerText: string
    bannerType: string
    topBannerFrom: string
    topBannerTo: string
    showAppPopUp: boolean
    appPopUpTitle: string
    appPopUpText: string
    appPopUpImage: string
}

interface MaintenanceMessage {
    operationsBody: string
    operationsIsActive: boolean
    operationsTitle: string
}
interface CustomerServiceMessage {
    operationsBody: string
    operationsDate: Date | null
    operationsTitle: string
}

export interface SettingsApiResponse {
    appMaintenanceMessageAndroid: MaintenanceMessage
    appMaintenanceMessageIOS: MaintenanceMessage
    appCustomerServiceMessage: CustomerServiceMessage
    weeklyDinnerRecipeList: WeeklyDinnerPair[]
    bannerInfo: BannerInfo
}
